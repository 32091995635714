var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "拒绝候选人",
        visible: _vm.dialogVisible,
        width: "600px",
        "before-close": _vm.cancelHandle,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("el-row", [_vm._v("确定拒绝该候选人吗？")]),
      _c(
        "el-form",
        {
          ref: "rejectForm",
          attrs: {
            model: _vm.rejectForm,
            rules: _vm.rejectFormRules,
            "label-width": "0",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "rejectReason", "label-position": "top" } },
            [
              _c(
                "el-checkbox-group",
                {
                  staticClass: "reject-checkbox",
                  model: {
                    value: _vm.rejectForm.rejectReason,
                    callback: function ($$v) {
                      _vm.$set(_vm.rejectForm, "rejectReason", $$v)
                    },
                    expression: "rejectForm.rejectReason",
                  },
                },
                [
                  _c("el-checkbox", {
                    attrs: { label: "公司及行业经验不匹配", name: "type" },
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "工作经验不足", name: "type" },
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "跳槽频繁", name: "type" },
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "学历不合格", name: "type" },
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "其他原因", name: "type" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "reason-detail",
              attrs: {
                prop: "remark",
                "label-position": "top",
                rules: _vm.rejectForm.rejectReason.includes("其他原因")
                  ? _vm.rejectFormRules.remark
                  : [{ required: false }],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 5,
                  placeholder: "选填拒绝原因和寻访记录（公开给所有猎头）",
                  resize: "none",
                },
                model: {
                  value: _vm.rejectForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.rejectForm, "remark", $$v)
                  },
                  expression: "rejectForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }