var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "反馈面试结果",
        visible: _vm.dialogVisible,
        width: "600px",
        "before-close": _vm.cancelHandle,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "processForm",
          attrs: {
            model: _vm.processForm,
            rules: _vm.processFormRules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "面试类型：", prop: "interviewType" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.processForm.interviewType,
                    callback: function ($$v) {
                      _vm.$set(_vm.processForm, "interviewType", $$v)
                    },
                    expression: "processForm.interviewType",
                  },
                },
                [_vm._v("现场面试")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.processForm.interviewType,
                    callback: function ($$v) {
                      _vm.$set(_vm.processForm, "interviewType", $$v)
                    },
                    expression: "processForm.interviewType",
                  },
                },
                [_vm._v("电话面试")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "面试结果：", prop: "result" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.processForm.result,
                    callback: function ($$v) {
                      _vm.$set(_vm.processForm, "result", $$v)
                    },
                    expression: "processForm.result",
                  },
                },
                [_vm._v("通过")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.processForm.result,
                    callback: function ($$v) {
                      _vm.$set(_vm.processForm, "result", $$v)
                    },
                    expression: "processForm.result",
                  },
                },
                [_vm._v("淘汰")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "3" },
                  model: {
                    value: _vm.processForm.result,
                    callback: function ($$v) {
                      _vm.$set(_vm.processForm, "result", $$v)
                    },
                    expression: "processForm.result",
                  },
                },
                [_vm._v("爽约")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "4" },
                  model: {
                    value: _vm.processForm.result,
                    callback: function ($$v) {
                      _vm.$set(_vm.processForm, "result", $$v)
                    },
                    expression: "processForm.result",
                  },
                },
                [_vm._v("待考虑")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "面试评价：", prop: "Evaluation" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 5,
                  placeholder: "填写面试评价，为猎头寻访提出建议",
                },
                model: {
                  value: _vm.processForm.Evaluation,
                  callback: function ($$v) {
                    _vm.$set(_vm.processForm, "Evaluation", $$v)
                  },
                  expression: "processForm.Evaluation",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }