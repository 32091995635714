<template>
    <el-dialog
        title="拒绝候选人"
        :visible.sync="dialogVisible"
        width="600px"
        v-loading="loading"
        :before-close="cancelHandle"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <el-row>确定拒绝该候选人吗？</el-row>
        <el-form :model="rejectForm" :rules="rejectFormRules" ref="rejectForm" label-width="0">
            <!-- <el-row>
                <el-col :span="24">
                    <el-checkbox v-model="rejectForm.reason.exp" label="公司及行业经验不匹配">公司及行业经验不匹配</el-checkbox>
                    <el-checkbox v-model="rejectForm.reason.workexp" label="工作经验不足">工作经验不足</el-checkbox>
                    <el-checkbox v-model="rejectForm.reason.jump" label="跳槽频繁">跳槽频繁</el-checkbox>
                    <el-checkbox v-model="rejectForm.reason.education" label="学历不合格">学历不合格</el-checkbox>
                    <el-checkbox v-model="rejectForm.reason.other" label="其他原因">其他原因</el-checkbox>
                </el-col>
            </el-row> -->
            <el-form-item prop="rejectReason" label-position="top">
                <el-checkbox-group class="reject-checkbox" v-model="rejectForm.rejectReason">
                    <el-checkbox label="公司及行业经验不匹配" name="type"></el-checkbox>
                    <el-checkbox label="工作经验不足" name="type"></el-checkbox>
                    <el-checkbox label="跳槽频繁" name="type"></el-checkbox>
                    <el-checkbox label="学历不合格" name="type"></el-checkbox>
                    <el-checkbox label="其他原因" name="type"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item class="reason-detail" prop="remark" label-position="top" :rules="rejectForm.rejectReason.includes('其他原因') ? rejectFormRules.remark : [{required: false}]">
                <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="选填拒绝原因和寻访记录（公开给所有猎头）"
                    v-model="rejectForm.remark"
                    resize="none"
                ></el-input>
            </el-form-item>

            <!-- <el-form-item prop="reason" label-position="top">
                <el-checkbox v-model="rejectForm.reason.exp" label="公司及行业经验不匹配">公司及行业经验不匹配</el-checkbox>
                <el-checkbox v-model="rejectForm.reason.workexp" label="工作经验不足">工作经验不足</el-checkbox>
                <el-checkbox v-model="rejectForm.reason.jump" label="跳槽频繁">跳槽频繁</el-checkbox>
                <el-checkbox v-model="rejectForm.reason.education" label="学历不合格">学历不合格</el-checkbox>
                <el-checkbox v-model="rejectForm.reason.other" label="其他原因">其他原因</el-checkbox>
            </el-form-item> -->
            <!-- <el-form-item prop="remark" label-position="top" :rules="rejectForm.reason.other ? rejectFormRules.remark : [{required: false}]">
                <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="选填拒绝原因和寻访记录（公开给所有猎头）"
                    v-model="rejectForm.remark"
                    resize="none"
                ></el-input>
            </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import myService from '#/js/service/myService.js';
    import { debug } from 'util';
    export default {
        name: "reject-dialog.vue",
        components: {
        },
        data(){
            return {
                dialogVisible:false,
                loading:false,
                remark: '',
                candidateId: "",
                btnText: "",
                reason: {
                    exp: '',
                    workexp: '',
                    jump: '',
                    education: '',
                    other: '',
                },
                rejectReason:[],
                rejectForm:{
                    remark: '',
                    rejectReason:[],
                    reason: {
                        exp: false,
                        workexp: false,
                        jump: false,
                        education: false,
                        other: false,
                    }
                },
                rejectFormRules:{
                    // reason: [
                    //     {
                    //         required: true,
                    //         message: '请选择拒绝原因',
                    //         trigger: 'blur'
                    //     }
                    // ],
                    rejectReason: [
                        { required: true, message: '请选择拒绝原因', trigger: 'change' }
                    ],
                    remark:
                        [
                            {
                                required: true,
                                message: '请输入原因',
                                trigger: 'blur'
                            },
                            {
                                max: 200,
                                min: 4,
                                message: '长度在 4 到 200 个字符',
                                trigger: 'blur'
                            }
                        ],
                }
            }
        },
        computed:{
            education(val){
                return this.rejectForm.reason.education;
            },
            exp(val){
                return this.rejectForm.reason.exp;
            },
            workexp(val){
                return this.rejectForm.reason.workexp;
            },
            jump(val){
                return this.rejectForm.reason.jump;
            },
            other(val){
                return this.rejectForm.reason.other;
            },
            unionId() {
                return this.$store.state.user.userInfo.unionId;
            },
        },
        watch: {
            reason (value){
                console.log(value);
            },
            education(val){
                let _index=this.rejectReason.indexOf('学历不合格')
                if(val){
                    this.rejectReason.push('学历不合格')
                }else{
                    if(_index>=0){
                        this.rejectReason.splice(_index , 1)
                    }
                }
            },
            exp(val){
                let _index=this.rejectReason.indexOf('公司及行业经验不匹配')
                if(val){
                    this.rejectReason.push('公司及行业经验不匹配')
                }else{
                    if(_index>=0){
                        this.rejectReason.splice(_index , 1)
                    }
                }
            },
            jump(val){
                let _index=this.rejectReason.indexOf('跳槽频繁')
                if(val){
                    this.rejectReason.push('跳槽频繁')
                }else{
                    if(_index>=0){
                        this.rejectReason.splice(_index , 1)
                    }
                }
            },
            other(val){
                let _index=this.rejectReason.indexOf('其他原因')
                if(val){
                    this.rejectReason.push('其他原因')
                }else{
                    if(_index>=0){
                        this.rejectReason.splice(_index , 1)
                    }
                }
            },
            workexp(val){
                let _index=this.rejectReason.indexOf('工作经验不足')
                if(val){
                    this.rejectReason.push('工作经验不足')
                }else{
                    if(_index>=0){
                        this.rejectReason.splice(_index , 1)
                    }
                }
            }
        },
        methods: {
            /**
             * 调用dialog
             * @param candidateId 候选人id
             */
            showHandle(candidateId, btnText) {
                this.candidateId = candidateId;
                this.btnText = btnText;
                this.dialogVisible = true;
                this.reset();
            },
            /**
             * 确认按钮fn
             */
            confirmHandle() {
                let reason = [];
                for (let key in this.reason){
                    this.reason[key] ? reason.push(this.reason[key]) : true;
                }

                this.$refs.rejectForm.validate((valid) => {
                    if (valid) {
                        // console.log(this.rejectForm.reason);
                        // console.log(this.rejectForm.rejectReason);
                        // console.log(this.rejectReason);
                        this.rejectReason = this.rejectForm.rejectReason;

                        this.loading = true;
                        let recommendPosition = this.$store.state.recommendInfo.recommendPosition;
                        if(recommendPosition === 'candidateDetail') {
                            _tracker.track('CandidateDetailRecommendOperation', JSON.stringify({
                                recommendId: this.candidateId,
                                text: this.btnText
                            }));
                        }else {
                            _tracker.track('RecommendOperation', JSON.stringify({
                                tableName: window._pageCode ? window._pageCode.replace('FloatingList', '') : '',
                                recommendId: this.candidateId,
                                position: recommendPosition,
                                text: this.btnText
                            }));
                        }

                        // myService.accept({
                        //     recommendationId: this.candidateId,
                        //     Acceptable: false,
                        //     rejectDetail: this.rejectForm.remark,
                        //     rejectReason: this.rejectReason,
                        //     __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                        // })
                        myService.acceptOrRreject({
                            recommendationId: this.candidateId,
                            unionId: this.unionId,
                            acceptable: false,
                            rejectDetail: this.rejectForm.remark,
                            rejectReason: this.rejectReason,
                        })
                        .then((res) => {
                            if(res) {
                                this.$emit('successFn', res);
                            }
                            this.dialogVisible = false;
                            this.loading = false;
                            this.$refs.rejectForm.resetFields();
                        }).catch(err => {
                            console.log(err);
                            this.loading = false;
                            this.$refs.rejectForm.resetFields();
                        });
                    } else {
                        console.log('error submit!!');
                    }
                });
            },
            cancelHandle() {
                this.dialogVisible = false;
                this.$refs.rejectForm.resetFields();
            },
            reset(){
                this.rejectForm.reason ={
                        exp: false,
                        workexp: false,
                        jump: false,
                        education: false,
                        other: false,
                };
                this.rejectReason = [];
            }
        }
    }
</script>


<style lang="scss" scoped>
    .el-row {
        line-height: 40px;
        margin-bottom: 10px;
        
        &:first-child,&:last-child {
            margin-bottom: 0;
        }
    }
    
    .el-form .el-form-item{
        margin-bottom: 0px;
    }
    .el-form .reason-detail {
        margin-top: 30px;
    }
    .reject-checkbox{
        white-space: normal;
        .el-checkbox{
            width: 40%;
        }
    }
</style>
