<template>
    <el-dialog
    title="淘汰候选人"
    :visible.sync="dialogVisible"
    width="612px"
    v-loading="loading"
    :before-close="cancelHandle"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
        <div>确定淘汰候选人吗？</div>
        <br>
        <el-form class="obsolete-form" :model="Form" :rules="FormRules" ref="Form" label-width="0">
            <el-form-item prop="reason">
                淘汰原因：

                <!-- 已接收待推进面试状态淘汰menu -->
                <template v-if="status && status == '1'">
                    <el-radio v-model="Form.reason" label="面试官不通过">面试官不通过</el-radio>
                    <el-radio v-model="Form.reason" label="有重复候选人">有重复候选人</el-radio>
                    <el-radio v-model="Form.reason" label="职位取消">职位取消</el-radio>
                    <el-radio v-model="Form.reason" label="其他原因">其他原因</el-radio>
                </template>
                <!-- 面试已反馈状态淘汰menu -->
                <template v-if="status && status == '4'">
                    <el-radio v-model="Form.reason" label="候选人爽约">候选人爽约</el-radio>
                    <el-radio v-model="Form.reason" label="面试官不通过">面试官不通过</el-radio>
                    <el-radio v-model="Form.reason" label="职位取消">职位取消</el-radio>
                    <el-radio v-model="Form.reason" label="其他原因">其他原因</el-radio>
                </template>
                <!-- 面试待安排状态淘汰menu -->
                <template v-if="status && status == '40'">
                    <el-radio v-model="Form.reason" label="猎头未安排">猎头未安排</el-radio>
                    <el-radio v-model="Form.reason" label="候选人放弃">候选人放弃</el-radio>
                    <el-radio v-model="Form.reason" label="面试官放弃">面试官放弃</el-radio>
                    <el-radio v-model="Form.reason" label="其他原因">其他原因</el-radio>
                </template>
                <!-- 面试待评估状态淘汰menu -->
                <template v-if="status && status == '41'">

                </template>
                <!-- offer状态淘汰menu -->
                <template v-if="status && ['7','8','9','10','11','12','13'].includes(status)">
                    <el-radio class="short-radio" v-model="Form.reason" label="Offer没谈拢">Offer没谈拢</el-radio>
                    <el-radio class="short-radio" v-model="Form.reason" label="候选人拒绝Offer">候选人拒绝Offer</el-radio>
                    <el-radio class="short-radio" v-model="Form.reason" label="候选人Offer被收回">候选人Offer被收回</el-radio>
                    <el-radio class="short-radio" v-model="Form.reason" label="其他原因">其他原因</el-radio>
                </template>
                <!-- 自己淘汰推荐的候选人 -->
                <template v-if="status && status == '0'">
                    <el-radio v-model="Form.reason" label="候选人放弃">候选人放弃</el-radio>
                    <el-radio v-model="Form.reason" label="HR反馈慢">HR反馈慢</el-radio>
                    <el-radio v-model="Form.reason" label="其他原因">其他原因</el-radio>
                </template>
                <!-- 接收对方推荐面试安排状态淘汰menu -->
                <template v-if="status && status == '42'">
                    <el-radio v-model="Form.reason" label="候选人放弃">候选人放弃</el-radio>
                    <el-radio v-model="Form.reason" label=" 面试官放弃">面试官放弃</el-radio>
                    <el-radio v-model="Form.reason" label="其他原因">其他原因</el-radio>
                </template>
                <!-- 催促推进面试状态淘汰menu -->
                <template v-if="status && status == '43'">
                    <el-radio v-model="Form.reason" label="HR已淘汰">HR已淘汰</el-radio>
                    <el-radio v-model="Form.reason" label="候选人放弃">候选人放弃</el-radio>
                    <el-radio v-model="Form.reason" label="HR反馈慢">HR反馈慢</el-radio>
                    <el-radio v-model="Form.reason" label="职位取消">职位取消</el-radio>
                    <el-radio v-model="Form.reason" label="其他原因">其他原因</el-radio>
                </template>
            </el-form-item>

            <!-- <el-form-item prop="reasonDetail">
                <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="填写更多原因"
                    v-model="Form.reasonDetail"
                ></el-input>
            </el-form-item> -->
            <el-form-item prop="reasonDetail" :rules="Form.reason == '其他原因' ? FormRules.reasonDetail : [{required: false}]">
                <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="填写更多原因"
                    v-model="Form.reasonDetail"
                ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import myService from '#/js/service/myService.js';

    export default {
        name: "obsolete-dialog",
        components: {
        },
        data(){
            return {
                dialogVisible:false,
                loading:false,
                btnText: '',
                Form: {
                    reasonDetail: '',
                    reason: ''
                },
                FormRules: {
                    reason:
                        [
                            {
                                required: true,
                                message: '请选择拒绝原因',
                                trigger: 'blur'
                            }
                        ],
                    reasonDetail:
                        [
                            {
                                required: true,
                                message: '请输入原因',
                                trigger: 'blur'
                            },
                            {
                                max: 200,
                                min: 4,
                                message: '长度在 4 到 200 个字符',
                                trigger: 'blur'
                            }
                        ],
                },
                status: 1
            }
        },
        watch: {
            'Form.reason': function (val) {
                // console.log(val);
            }
        },
        computed: {
            unionId() {
                return this.$store.state.user.userInfo.unionId;
            },
        },
        methods: {
            /**
             * 调用dialog
             * @param candidateId 候选人id
             */
            showHandle (candidateId, status, btnText){
                this.candidateId = candidateId;
                this.btnText = btnText;
                this.dialogVisible = true;
                this.status = String(status) || '';
            },
            /**
             * 确认按钮fn
             */
            confirmHandle(){
                this.$refs.Form.validate((valid) => {
                    if (valid) {
                        // console.log(this.Form.reason);
                        this.loading = true;
                        let recommendPosition = this.$store.state.recommendInfo.recommendPosition;
                        if(recommendPosition === 'candidateDetail') {
                            _tracker.track('CandidateDetailRecommendOperation', JSON.stringify({
                                recommendId: this.candidateId,
                                text: this.btnText
                            }));
                        }else {
                            _tracker.track('RecommendOperation', JSON.stringify({
                                tableName: window._pageCode ? window._pageCode.replace('FloatingList', '') : '',
                                recommendId: this.candidateId,
                                position: recommendPosition,
                                text: this.btnText
                            }));
                        }
                        
                        // myService.obsolete({
                        //     Reason: this.Form.reason,
                        //     ReasonDetail: this.Form.reasonDetail,
                        //     recommendationId: this.candidateId,
                        //     __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                        // }).then((res) => {
                        myService.recommendObsolete({
                            unionId: this.unionId,
                            Reason: this.Form.reason,
                            ReasonDetail: this.Form.reasonDetail,
                            recommendationId: this.candidateId,
                        }).then((res) => {
                            if (res) {
                                this.$emit('successFn', res);
                            }
                            this.dialogVisible = false;
                            this.loading = false;
                            this.$refs.Form.resetFields();
                        }).catch(err => {
                            console.log(err);
                            this.loading = false;
                            this.$refs.Form.resetFields();
                        });
                    } else {
                        console.log('error submit!!');
                    }
                })
            },
            cancelHandle(){
                this.dialogVisible = false;
                this.$refs.Form.resetFields();
            }
        }
    }
</script>

<style lang="scss" scoped>
.obsolete-form {
    .short-radio {
        margin-right: 15px;
    }
}
</style>
