var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "淘汰候选人",
        visible: _vm.dialogVisible,
        width: "612px",
        "before-close": _vm.cancelHandle,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", [_vm._v("确定淘汰候选人吗？")]),
      _c("br"),
      _c(
        "el-form",
        {
          ref: "Form",
          staticClass: "obsolete-form",
          attrs: { model: _vm.Form, rules: _vm.FormRules, "label-width": "0" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "reason" } },
            [
              _vm._v("\n            淘汰原因：\n\n            "),
              _vm.status && _vm.status == "1"
                ? [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "面试官不通过" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("面试官不通过")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "有重复候选人" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("有重复候选人")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "职位取消" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("职位取消")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "其他原因" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("其他原因")]
                    ),
                  ]
                : _vm._e(),
              _vm.status && _vm.status == "4"
                ? [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "候选人爽约" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("候选人爽约")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "面试官不通过" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("面试官不通过")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "职位取消" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("职位取消")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "其他原因" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("其他原因")]
                    ),
                  ]
                : _vm._e(),
              _vm.status && _vm.status == "40"
                ? [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "猎头未安排" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("猎头未安排")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "候选人放弃" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("候选人放弃")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "面试官放弃" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("面试官放弃")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "其他原因" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("其他原因")]
                    ),
                  ]
                : _vm._e(),
              _vm.status && _vm.status == "41" ? void 0 : _vm._e(),
              _vm.status &&
              ["7", "8", "9", "10", "11", "12", "13"].includes(_vm.status)
                ? [
                    _c(
                      "el-radio",
                      {
                        staticClass: "short-radio",
                        attrs: { label: "Offer没谈拢" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("Offer没谈拢")]
                    ),
                    _c(
                      "el-radio",
                      {
                        staticClass: "short-radio",
                        attrs: { label: "候选人拒绝Offer" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("候选人拒绝Offer")]
                    ),
                    _c(
                      "el-radio",
                      {
                        staticClass: "short-radio",
                        attrs: { label: "候选人Offer被收回" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("候选人Offer被收回")]
                    ),
                    _c(
                      "el-radio",
                      {
                        staticClass: "short-radio",
                        attrs: { label: "其他原因" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("其他原因")]
                    ),
                  ]
                : _vm._e(),
              _vm.status && _vm.status == "0"
                ? [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "候选人放弃" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("候选人放弃")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "HR反馈慢" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("HR反馈慢")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "其他原因" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("其他原因")]
                    ),
                  ]
                : _vm._e(),
              _vm.status && _vm.status == "42"
                ? [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "候选人放弃" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("候选人放弃")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: " 面试官放弃" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("面试官放弃")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "其他原因" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("其他原因")]
                    ),
                  ]
                : _vm._e(),
              _vm.status && _vm.status == "43"
                ? [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "HR已淘汰" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("HR已淘汰")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "候选人放弃" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("候选人放弃")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "HR反馈慢" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("HR反馈慢")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "职位取消" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("职位取消")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "其他原因" },
                        model: {
                          value: _vm.Form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.Form, "reason", $$v)
                          },
                          expression: "Form.reason",
                        },
                      },
                      [_vm._v("其他原因")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "reasonDetail",
                rules:
                  _vm.Form.reason == "其他原因"
                    ? _vm.FormRules.reasonDetail
                    : [{ required: false }],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 5,
                  placeholder: "填写更多原因",
                },
                model: {
                  value: _vm.Form.reasonDetail,
                  callback: function ($$v) {
                    _vm.$set(_vm.Form, "reasonDetail", $$v)
                  },
                  expression: "Form.reasonDetail",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }