import {pingan as pinganUrl} from '#/js/config/api.json';
import tool from "#/js/tool";

export default {
    /**
     * 推荐给客户
     * @param
     * @returns {*}
     */
    recommendCustomer: (id) => {
        return _request({
            method: 'POST',
            url: pinganUrl.recommend_customer.replace(/\%p/ig, id),
            throwBusinessError: true
        }).then(res => {
            return Promise.resolve(res);
        },err => {
            if(!err.succeeded){
                shortTips(err.message);
            } else {
                return Promise.reject(err.data);
            }
        })
    },
    getRecommendationStatus: (id) => {
        return _request({
            method: 'POST',
            url: pinganUrl.get_recommendation_status.replace(/\%p/ig, id),
            data: {}
        })
    },
    getRecommendationStatusList: (id) => {
        return _request({
            method: 'POST',
            url: pinganUrl.get_recommendation_status_list.replace(/\%p/ig, id),
            data: {}
        })
    }
}
