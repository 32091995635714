import { render, staticRenderFns } from "./reject-dialog.vue?vue&type=template&id=98989c90&scoped=true&"
import script from "./reject-dialog.vue?vue&type=script&lang=js&"
export * from "./reject-dialog.vue?vue&type=script&lang=js&"
import style0 from "./reject-dialog.vue?vue&type=style&index=0&id=98989c90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98989c90",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('98989c90')) {
      api.createRecord('98989c90', component.options)
    } else {
      api.reload('98989c90', component.options)
    }
    module.hot.accept("./reject-dialog.vue?vue&type=template&id=98989c90&scoped=true&", function () {
      api.rerender('98989c90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/component/common/dialog/reject-dialog.vue"
export default component.exports