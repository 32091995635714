<template>
    <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="500px"
    v-loading="loading"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
>
    <el-row>确定接受该候选人吗？ <span class="color-red" v-show="isShowMoreText">该候选人为非公司内部人员推荐，我们企业库也有此人才。</span></el-row>
    <span slot="footer" class="dialog-footer">
        <el-button @click="cancelHandle">取消</el-button>
        <el-button type="primary" @click="confirmHandle">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import myService from '#/js/service/myService.js';

    export default {
        name: "accept-dialog.vue",
        components: {
        },
        data() {
            return {
                candidateId: "",
                btnText: "",
                isShowMoreText: false,
                dialogVisible:false,
                loading:false,
            }
        },
        computed: {
            unionId() {
                return this.$store.state.user.userInfo.unionId;
            },
        },
        methods: {
            /**
             * 调用dialog
             * @param candidateId 候选人id
             */
            showHandle(candidateId, btnText, isShowMoreText = false) {
                this.candidateId = candidateId;
                this.btnText = btnText;
                this.isShowMoreText = isShowMoreText;
                this.dialogVisible=true;
            },
            /**
             * 确认按钮fn
             */
            confirmHandle() {
                this.loading = true;
                let recommendPosition = this.$store.state.recommendInfo.recommendPosition;
                if(recommendPosition === 'candidateDetail') {
                    _tracker.track('CandidateDetailRecommendOperation', JSON.stringify({
                        recommendId: this.candidateId,
                        text: this.btnText
                    }));
                }else {
                    _tracker.track('RecommendOperation', JSON.stringify({
                        tableName: window._pageCode ? window._pageCode.replace('FloatingList', '') : '',
                        recommendId: this.candidateId,
                        position: recommendPosition,
                        text: this.btnText
                    }));
                }

                // myService.accept({
                //     recommendationId: this.candidateId,
                //     Acceptable : true,
                //     __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                // })
                myService.acceptOrRreject({
                    recommendationId: this.candidateId,
                    unionId: this.unionId,
                    acceptable: true,
                })
                .then((res) => {
                    if(res) {
                        this.$emit('successFn', res);
                    }
                    this.dialogVisible=false;
                    this.loading = false;
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
            },
            cancelHandle() {
                this.dialogVisible = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .el-row {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .color-red{
        color: red;
    }
</style>
