<template>
    <el-dialog
        title="反馈面试结果"
        :visible.sync="dialogVisible"
        width="600px"
        v-loading="loading"
        :before-close="cancelHandle"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <el-form :model="processForm" :rules="processFormRules" ref="processForm" label-width="100px">
            <el-form-item label="面试类型：" prop="interviewType">
                <el-radio v-model="processForm.interviewType" label="0">现场面试</el-radio>
                <el-radio v-model="processForm.interviewType" label="1">电话面试</el-radio>
            </el-form-item>
            <el-form-item label="面试结果：" prop="result">
                <el-radio v-model="processForm.result" label="1">通过</el-radio>
                <el-radio v-model="processForm.result" label="2">淘汰</el-radio>
                <el-radio v-model="processForm.result" label="3">爽约</el-radio>
                <el-radio v-model="processForm.result" label="4">待考虑</el-radio>
            </el-form-item>

            <el-form-item label="面试评价：" prop="Evaluation">
                <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="填写面试评价，为猎头寻访提出建议"
                    v-model="processForm.Evaluation"
                ></el-input>
                <!-- <label>
                    <input type="checkbox" v-model="processForm.SharedResult" class="icheck" name="SharedResult">
                    公开到职位页
                </label> -->
                <!-- <el-tooltip class="item" effect="light" content="将面试评价和寻访建议在职位页动态栏呈现" placement="right">
                    <i class="el-icon-info icheck-icon"></i>
                </el-tooltip> -->
            </el-form-item>

        </el-form>
        
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import myService from '#/js/service/myService.js';

    export default {
        name: "process-dialog.vue",
        components: {
        },
        data (){
            return {
                dialogVisible:false,
                loading:false,
                candidateId: '',
                btnText: '',
                processForm: {
                    interviewType: '',
                    result: '',
                    Evaluation: '',
                    SharedResult: false,
                },
                processFormRules: {
                    interviewType:
                        [
                            {
                                required: true,
                                message: '请选择面试类型',
                                trigger: 'blur'
                            }
                        ],
                    result:
                        [
                            {
                                required: true,
                                message: '请选择面试结果',
                                trigger: 'blur'
                            }
                        ],
                    Evaluation:
                        [
                            {
                                required: true,
                                message: '请输入面试评价',
                                trigger: 'blur'
                            }, {
                                max: 400,
                                message: '长度不能超过400个字',
                                trigger: 'blur'
                            }
                        ],

                }
            }
        },
        computed: {
            unionId() {
                return this.$store.state.user.userInfo.unionId;
            },
        },
        methods: {
            /**
             * 调用dialog
             * @param candidateId 候选人id
             */
            showHandle (candidateId, btnText){
                this.candidateId = candidateId;
                this.btnText = btnText;
                this.processForm = {
                    interviewType: '',
                    result: '',
                    Evaluation: '',
                    SharedResult: false,
                };
                this.dialogVisible = true;
            },
            /**
             * 确认按钮fn
             */
            confirmHandle(){
                this.$refs.processForm.validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        let recommendPosition = this.$store.state.recommendInfo.recommendPosition;
                        if(recommendPosition === 'candidateDetail') {
                            _tracker.track('CandidateDetailRecommendOperation', JSON.stringify({
                                recommendId: this.candidateId,
                                text: this.btnText
                            }));
                        }else {
                            _tracker.track('RecommendOperation', JSON.stringify({
                                tableName: window._pageCode ? window._pageCode.replace('FloatingList', '') : '',
                                recommendId: this.candidateId,
                                position: recommendPosition,
                                text: this.btnText
                            }));
                        }

                        // myService.feedback({
                        //     __RequestVerificationToken: this.$store.state.verificationToken.verificationToken,
                        //     interviewId: this.candidateId,
                        //     InterviewType: this.processForm.interviewType,
                        //     Result: this.processForm.result,
                        //     Evaluation: this.processForm.Evaluation,
                        //     SharedResult: this.processForm.SharedResult
                        // })
                        myService.interviewFeedback({
                            unionId: this.unionId,
                            interviewId: this.candidateId,
                            InterviewType: this.processForm.interviewType,
                            Result: this.processForm.result,
                            Evaluation: this.processForm.Evaluation,
                            SharedResult: this.processForm.SharedResult
                        })
                        .then((res) => {
                            if (res) {
                                this.$emit('successFn', res);
                            }
                            this.loading = false;
                            this.dialogVisible = false;
                            this.$refs.processForm.resetFields();
                        }).catch(err => {
                            console.log(err);
                            this.loading = false;
                            this.$refs.processForm.resetFields();
                        });
                    } else {
                        console.log('error submit!!');
                    }
                });
            },
            cancelHandle(){
                this.dialogVisible = false;
                this.$refs.processForm.resetFields();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .el-row {
        line-height: 40px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .red{
        color: red;
    }

    .errMsg{
        padding: 10px;
        color: red;
    }
    label {
        display: inline-block;
        position: relative;
        cursor: pointer;
    }
    .icheck-icon{
        cursor: pointer;
        width: 16px;
        height: 16px;
        margin-left: 8px;
    }
</style>
