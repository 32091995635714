var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "安排面试",
        visible: _vm.dialogVisible,
        width: "600px",
        "before-close": _vm.cancelHandle,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "grid-content bg-purple" }, [
              _vm._v("候选人："),
            ]),
          ]),
          _c("el-col", { attrs: { span: 20 } }, [
            _c("div", { staticClass: "grid-content bg-purple-light" }, [
              _vm._v(_vm._s(_vm.data.realName)),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "grid-content bg-purple" }, [
              _vm._v("面试职位："),
            ]),
          ]),
          _c("el-col", { attrs: { span: 20 } }, [
            _c("div", { staticClass: "grid-content bg-purple-light" }, [
              _vm._v(_vm._s(_vm.data.jobName)),
            ]),
            _c(
              "div",
              { staticClass: "grid-content bg-purple-light" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "0" },
                    model: {
                      value: _vm.interviewForm.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.interviewForm, "type", $$v)
                      },
                      expression: "interviewForm.type",
                    },
                  },
                  [_vm._v("现场面试")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    model: {
                      value: _vm.interviewForm.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.interviewForm, "type", $$v)
                      },
                      expression: "interviewForm.type",
                    },
                  },
                  [_vm._v("电话面试")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "interviewForm",
          attrs: {
            model: _vm.interviewForm,
            rules: _vm.interviewFormRules,
            "label-width": "0",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _vm._v("面试时间："),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "date" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              "picker-options": _vm.datePickerOption,
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.interviewForm.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.interviewForm, "date", $$v)
                              },
                              expression: "interviewForm.date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "time" } },
                        [
                          _c("el-time-select", {
                            attrs: {
                              "picker-options": {
                                start: "00:00",
                                step: "00:15",
                                end: "24:00",
                              },
                              "value-format": "HH:mm",
                              placeholder: "选择时间",
                            },
                            model: {
                              value: _vm.interviewForm.time,
                              callback: function ($$v) {
                                _vm.$set(_vm.interviewForm, "time", $$v)
                              },
                              expression: "interviewForm.time",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.interviewForm.type === "0"
            ? _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("面试地点："),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c("el-form-item", { attrs: { prop: "address" } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 2, maxRows: 4 },
                                placeholder: "请输入面试地址",
                                resize: "none",
                              },
                              model: {
                                value: _vm.interviewForm.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.interviewForm, "address", $$v)
                                },
                                expression: "interviewForm.address",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.interviewsList.length >= 2
            ? _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("最终轮面试："),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 20 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-light" },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.interviewForm.isLastInterview,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.interviewForm,
                                "isLastInterview",
                                $$v
                              )
                            },
                            expression: "interviewForm.isLastInterview",
                          },
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.interviewForm.isLastInterview ? "是" : "否"
                            ) +
                            "\n            "
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _vm._v("联系人："),
                ]),
              ]),
              _c("el-col", { attrs: { span: 20 } }, [
                _c("div", { staticClass: "grid-content bg-purple-light" }, [
                  _vm._v(
                    _vm._s(_vm.data.headhunterRealName) +
                      "@" +
                      _vm._s(_vm.data.headhunterNickname) +
                      "\n            "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }