var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.cancelHandle,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.status === 4 || _vm.status === 64
        ? _c("span", [_vm._v("请确认候选人进入面试吗？")])
        : _vm._e(),
      _vm.status === 16
        ? _c(
            "div",
            { staticClass: "entry-dialog entry-date" },
            [
              _c("span", { staticClass: "entry-dialog-start" }, [_vm._v("*")]),
              _vm._v("入职时间\n        "),
              _c("el-date-picker", {
                attrs: {
                  clearable: false,
                  type: "date",
                  placeholder: "选择日期",
                },
                model: {
                  value: _vm.currentDate,
                  callback: function ($$v) {
                    _vm.currentDate = $$v
                  },
                  expression: "currentDate",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.status === 16
        ? _c(
            "div",
            { staticClass: "entry-dialog" },
            [
              _c("span", { staticClass: "entry-dialog-start" }, [_vm._v("*")]),
              _vm._v("保证期\n        "),
              _c(
                "el-checkbox-group",
                {
                  attrs: { min: 1, max: 2 },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.defaultGuarantee,
                    callback: function ($$v) {
                      _vm.defaultGuarantee = $$v
                    },
                    expression: "defaultGuarantee",
                  },
                },
                _vm._l(_vm.guaranteePeriods, function (guaranteePeriod) {
                  return _c(
                    "el-checkbox",
                    { key: guaranteePeriod, attrs: { label: guaranteePeriod } },
                    [_vm._v(_vm._s(guaranteePeriod))]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.status === 32
        ? _c("span", [_vm._v("请确认候选人已离职吗？")])
        : _vm._e(),
      _vm.status === 407
        ? _c("span", [_vm._v("请确认候选人进入Offering中？")])
        : _vm._e(),
      _vm.status === 1024
        ? _c("span", [
            _vm._v(
              "确定将候选人恢复到" +
                _vm._s(`"${_vm.lastStatusText}"状态`) +
                "吗？"
            ),
          ])
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }