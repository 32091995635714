<!-- 进入面试，离职，入职 dialog -->
<template>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="500px"
        v-loading="loading"
        :before-close="cancelHandle"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <span v-if="status === 4 || status === 64">请确认候选人进入面试吗？</span>
        <!--<span v-if="status === 16">请确认候选人已入职吗？</span>-->
        <div v-if="status === 16" class="entry-dialog entry-date">
            <span class="entry-dialog-start">*</span>入职时间
            <el-date-picker
                v-model="currentDate"
                :clearable="false"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
        </div>
        <div v-if="status === 16" class="entry-dialog">
            <span class="entry-dialog-start">*</span>保证期
            <el-checkbox-group
                v-model="defaultGuarantee"
                @change="handleChange"
                :min="1"
                :max="2">
                <el-checkbox v-for="guaranteePeriod in guaranteePeriods" :label="guaranteePeriod" :key="guaranteePeriod">{{guaranteePeriod}}</el-checkbox>
            </el-checkbox-group>
        </div>
        <span v-if="status === 32">请确认候选人已离职吗？</span>
        <span v-if="status === 407">请确认候选人进入Offering中？</span>
        <span v-if="status === 1024">确定将候选人恢复到{{`"${lastStatusText}"状态`}}吗？</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>

    // 此处为备注，无实际意义
    const stage = {
        '离职' : 32,
        '入职' : 16,
        '进入面试' : 4,
        '恢复': 1024
    }

    const PERIOD_MAP = {
        '无': 0,
        '三个月': 90,
        '六个月': 180
    }

    import myService from '#/js/service/myService.js';
    import { mapActions, mapGetters } from 'vuex';
    export default {
        name: "stage-dialog.vue",
        components: {
        },
        data (){
            return {
                dialogVisible:false,
                loading:false,
                btnText: "",
                guaranteePeriods: ['无', '三个月', '六个月'],
                defaultGuarantee: ['三个月'],
                currentDate: new Date(),
                lastStatusText: '',
                lastStatus: '',
            }
        },
        computed: {
            unionId() {
                return this.$store.state.user.userInfo.unionId;
            },
            title(){
                if(this.status === 16) {
                    return '入职';
                }else {
                    return '提示';
                }
            }
        },
        props: {
            status : {
                type: Number,
                default: 16,
            },
            isOperationPage: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            /**
             * 调用dialog
             * @param id
             */
            showHandle (id, btnText, lastStatusText, lastStatus){
                this.id = id;
                this.btnText = btnText;
                this.dialogVisible = true;
                this.lastStatusText = lastStatusText || '';
                this.lastStatus = lastStatus || '';
            },
            async confirmHandle(){
                if(!this.currentDate){
                    return shortTips('入职时间不能为空。')
                }
                this.loading = true;

                let recommendPosition = this.$store.state.recommendInfo.recommendPosition;
                if(recommendPosition === 'candidateDetail') {
                    _tracker.track('CandidateDetailRecommendOperation', JSON.stringify({
                        recommendId: this.id,
                        text: this.btnText
                    }));
                }else {
                    _tracker.track('RecommendOperation', JSON.stringify({
                        tableName: window._pageCode ? window._pageCode.replace('FloatingList', '') : '',
                        recommendId: this.id,
                        position: recommendPosition,
                        text: this.btnText
                    }));
                }

                if(this.$props.status == 1024) {
                    // myService.recover({
                    //     recommendationId: this.id,
                    //     __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                    // }).then((res) => {
                    myService.recommendRecover(this.id).then((res) => {
                        if (res) {
                            this.$emit('successFn', res);
                        }
                        this.dialogVisible = false;
                        this.loading = false;
                        shortTips('恢复成功');
                    }).catch(err => {
                        console.log(err);
                        this.loading = false;
                        shortTips(err.message || '系统异常，请稍后重试');
                    });
                } else if(this.status == 407) {
                    this.entryOffering();
                } else {
                    if([7, 9, 10, 11, 12].includes(this.lastStatus)) {
                        await this.updateOfferingStage(this.id, this.lastStatus+1, true);
                    }
                    this._entryRequest();
                }
            },
            entryOffering() {
                const params = {
                    recommendationId: this.id,
                    unionId: this.unionId,
                };
                myService.recommendEntryOffering(params)
                .then(res => {
                    this.dialogVisible = false;
                    this.$emit('successFn', res);
                })
                .catch(() => {
                    shortTips(err.message || '系统异常，请稍后重试');
                })
                .finally(() => {
                    this.loading = false;
                });
            },
            _entryRequest() {
                // myService.entry({
                //     RecommendationId: this.id,
                //     Status: this.$props.status === 64 ? 4 : this.$props.status,
                //     ExtensionContent: this.$props.status === 4 ? '' : undefined,
                //     GuaranteeDays: this.$props.status === 16 ? PERIOD_MAP[this.defaultGuarantee.join()] : undefined,
                //     OnboardDate: this.$props.status === 16 ? this.currentDate.toLocaleDateString() : undefined,
                //     __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                // }).then((res) => {

                let params = {
                    RecommendationId: this.id,
                    unionId: this.unionId,
                    Status: this.$props.status === 64 ? 4 : this.$props.status,
                    ExtensionContent: this.$props.status === 4 ? '' : undefined,
                    GuaranteeDays: this.$props.status === 16 ? PERIOD_MAP[this.defaultGuarantee.join()] : undefined,
                    OnboardDate: this.$props.status === 16 ? this.currentDate.toLocaleDateString() : undefined,
                };
                myService.recommendStage(params).then((res) => {
                    if(res) {
                        this.$emit('successFn', res, params.OnboardDate);
                        if(!this.isOperationPage && (this.$props.status == 16 || this.$props.status == 32)) {
                            this.getLockStatusAsync(this.id);
                        }
                    }
                    this.loading = false;
                    this.dialogVisible = false;
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
            },
            updateOfferingStage(recommendationId, nextStatus, isCompleteOffering) {
                if(this.$props.status == 13) return Promise.resolve(true);
                const params = {
                    unionId: this.unionId,
                    isCompleteOffering: isCompleteOffering || false,
                    status: nextStatus,
                    recommendationId: recommendationId,
                };
                // return myService.updateOfferingStage(params);
                return myService.recommendOfferingStage(params);
            },
            cancelHandle(){
                // TODO
                this.dialogVisible = false;
            },
            handleChange() {
                this.defaultGuarantee.splice(0,1);
            },
            ...mapActions([
                'getLockStatusAsync'
            ])
        }
    }
</script>

<style lang="scss">
    .entry-dialog {
        text-align: center;

        &-start {
            color: #FF6564;
        }

        .el-checkbox-group {
            margin-left: 10px;
            display: inline-block;

            .el-checkbox {
                margin-right: 20px;
            }
        }
    }

    .entry-date {
        margin-bottom: 20px;
        margin-left: 10px;

        .el-date-editor--date {
            margin-left: 20px;
        }
    }
</style>
